<template>
  <el-menu
    mode="horizontal"
    class="top-menu"
    :default-active="$route.path"
    background-color="#002033"
    text-color="#fff"
    active-text-color="#ffd04b"
    router
  >
    <el-menu-item index="/">
      <span slot="title">首页</span>
    </el-menu-item>
    <el-menu-item index="/newsCenter">
      <span slot="title" class="margin">新闻中心</span>
    </el-menu-item>
    <el-menu-item index="/identity">
      <span slot="title" class="margin">参展参会</span>
    </el-menu-item>
    <el-menu-item index="/exhibitorService">
      <span slot="title" class="margin">展会服务</span>
    </el-menu-item>
    <el-menu-item index="/aboutMeeting">
      <span slot="title">关于农贸会</span>
    </el-menu-item>
  </el-menu>
</template>

<script>
export default {
  name: "top-menu",
};
</script>

<style scoped lang="less">
.margin {
  margin: 0 10px;
}
/deep/.el-menu,
.el-menu--horizontal {
  height: 40px;
  background: #e0e0e0 !important;
  display: flex;
  justify-content: center;
}
/deep/.el-menu-item {
  height: 40px !important;
  line-height: 40px !important;
  background: #e0e0e0 !important;
  color: #000 !important;
  font-weight: bold;
}
</style>