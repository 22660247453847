<template>
  <el-container class="layout-container">
    <el-container>
      <el-header class="header">
        <div class="header-top">
          <div class="logo-wrap" @click="$router.push('/')">
            <img src="../../assets/home/title-logo.png" />
            <img
              style="margin-left: 10px"
              src="../../assets/home/title-text.png"
            />
          </div>
          <div class="right-menu">
            <el-input
              placeholder="搜索"
              suffix-icon="el-icon-search"
              v-model="searchInfo"
            >
            </el-input>
            <div class="login" @click="$router.push('identity')">登录 注册</div>
          </div>
        </div>
        <div class="header-bottom">
          <top-menu />
        </div>
      </el-header>
      <el-main class="main">
        <!-- 子路由出口 -->
        <router-view></router-view>
      </el-main>
      <el-footer class="footer">
        <div @click.stop="jumpConnect">陕ICP备2022005884号-3</div>
      </el-footer>
    </el-container>
  </el-container>
</template>

<script>
import TopMenu from "./components/topMenu.vue";
export default {
  name: "LayoutIndex",
  components: { TopMenu },
  data() {
    return {
      searchInfo: "",
    };
  },
  methods: {
    jumpConnect() {
      window.open("https://beian.miit.gov.cn/", "_blank");
    },
  },
};
</script>

<style scoped lang="less">
.layout-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #e0e0e0;
  .header {
    background: #0025af;
    .header-top {
      height: 60px !important;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logo-wrap {
        width: 200px;
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          height: 50%;
        }
      }
      .right-menu {
        float: right;
        display: flex;
        align-items: center;
        .login {
          width: 80px;
          color: #fff;
          font-weight: bold;
          font-size: 10px;
          border: 2px solid #fff;
          border-radius: 10px;
          text-align: center;
          padding: 3px 8px;
          cursor: pointer;
        }
        /deep/ .el-input__inner {
          width: 270px;
          height: 30px;
          background: #0025af;
          border: 2px solid #fff;
          border-radius: 20px;
          color: #fff;
        }
        /deep/ .el-input__inner:focus {
          border: 2px solid #fff;
        }
      }
    }
  }
  .main {
    margin-top: 41px;
    background: #fff;
  }
  .footer {
    background: #fff;
    text-align: center;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
  }
}
/deep/.el-card__body,
.el-main {
  padding: 0;
}

/deep/ .el-input__icon {
  width: 66px;
  line-height: 30px;
  color: #fff;
}
</style>