import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '@/components/layout';

Vue.use(VueRouter);
const routes = [
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/login/login'),
	},
	{
		path: '/404',
		name: '404',
		component: () => import('@/views/common/404page'),
	},
	{
		path: '/',
		component: Layout,
		children: [
			{
				path: '',
				name: 'index',
				component: () => import('@/views/home'),
			},
			{
				path: '/create',
				name: 'create',
				component: () => import('@/views/login/create'),
			},
			{
				path: '/identity',
				name: 'identity',
				component: () => import('@/views/identity'),
			},
			{
				path: '/exhibitorAndCommodity/commodity',
				name: 'commodity',
				component: () => import('@/views/exhibitorAndCommodity/commodity'),
			},
			{
				path: '/meetingBooth',
				name: 'meetingBooth',
				component: () => import('@/views/home/meetingBooth'),
			},
			{
				path: '/meetingData',
				name: 'meetingData',
				component: () => import('@/views/home/meetingData'),
			},
			{
				path: '/merchantData',
				name: 'merchantData',
				component: () => import('@/views/home/merchantData'),
			},
			{
				path: '/exhibitorService/',
				name: 'exhibitorService',
				component: () => import('@/views/exhibitorService/abuttingJoint'),
				children: [
					{
						path: '',
						name: 'abuttingJoint',
						component: () => import('@/views/exhibitorService/abuttingJoint'),
					},
				],
			},
			{
				path: '/newsCenter/',
				name: 'newsCenter',
				component: () => import('@/views/newsCenter/newsTitle'),
				children: [
					{
						path: '',
						name: 'abuttingJoint',
						component: () => import('@/views/newsCenter/newsTitle'),
					},
				],
			},
			{
				path: '/aboutMeeting/',
				name: 'aboutMeeting',
				component: () => import('@/views/aboutMeeting/aboutMeeting'),
			},
			//   {
			//     path: '/forecastSupport',
			//     name: 'forecastSupport',
			//     component: () => import('@/views/forecastSupport')
			//   },
			//   {
			//     path: '/guidanceProduct',
			//     name: 'guidanceProduct',
			//     component: () => import('@/views/guidanceProduct')
			//   },
			//   {
			//     path: '/monitorAssess',
			//     name: 'monitorAssess',
			//     component: () => import('@/views/monitorAssess')
			//   },
			//   {
			//     path: '/warningServe',
			//     name: 'warningServe',
			//     component: () => import('@/views/warningServe')
			//   },
			//   {
			//     path: '/warningCount',
			//     name: 'warningCount',
			//     component: () => import('@/views/warningCount')
			//   },
			//   {
			//     path: '/riskWarning',
			//     name: 'riskWarning',
			//     component: () => import('@/views/riskWarning')
			//   },
		],
	},
];

const router = new VueRouter({
	routes,
});
//全局路由守卫
router.beforeEach((to, from, next) => {
	// 如果要访问的页面不是 /login，校验登录状态
	// 如果没有登录，则跳转到登录页面
	// 如果登录了，则允许通过
	const user = JSON.parse(localStorage.getItem('user'));
	if (!to.path === '/login') {
		if (user) {
			next();
		} else {
			next('/login');
		}
	} else {
		next();
	}
});
export default router;
